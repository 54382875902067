/* src/components/HeroSlider.css */
btn {
    color: purple;
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid purple;
    padding: 10px 20px;
    font-size: 17px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
    position: relative;
    transition: all 1s;
    overflow: hidden;
  }
  
  .btn:hover {
    color: white;
  }
  
  .btn::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0;
    left: -40px;
    transform: skewX(45deg);
    background-color: purple;
    z-index: -1;
    transition: all 1s;
  }
  
  .btn:hover::before {
    width: 160%;
  }

  

/* src/components/HeroSlider.css */

.hero-slider-container {
    width: 100%;
    height: 60vh; /* Set container height to 60vh */
  }
  
  .hero-swiper {
    width: 100%;
    height: 100%; /* Ensure the Swiper takes up the full height */
  }
  
  .slide {
    width: 100%;
    height: 60vh; /* Set each slide height to 60vh */
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Dark overlay for better text visibility */
    z-index: 1;
  }
  
  .slide-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
  }
  
  .slide-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  

  
  