/* Container for the borders */
.border-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure borders don't interfere with interactions */
  overflow: hidden; /* Prevent borders from overflowing */
}

/* Common styles for border lines */
.border-line {
  position: absolute;
  background-color: #000; /* Border color */
  pointer-events: none;
  transition: transform 0.3s ease; /* Reduced duration from 0.5s to 0.3s */
}

/* Top border */
.top-line {
  top: 0;
  left: 0;
  height: 2px; /* Thickness for top border */
  width: 100%;
  transform: scaleX(0); /* Initially hidden */
  transform-origin: left; /* Start from the left */
  transition: transform 0.3s ease; /* Phase 1 with reduced duration */
}

/* Left border */
.left-line {
  bottom: 0;
  left: 0;
  width: 2px; /* Thickness for left border */
  height: 100%;
  transform: scaleY(0); /* Initially hidden */
  transform-origin: bottom; /* Start from the bottom */
  transition: transform 0.3s ease 0.3s; /* Reduced delay from 0.5s to 0.3s */
}

/* Right border */
.right-line {
  top: 0;
  right: 0;
  width: 2px; /* Thickness for right border */
  height: 100%;
  transform: scaleY(0); /* Initially hidden */
  transform-origin: top; /* Start from the top */
  transition: transform 0.3s ease 0.3s; /* Reduced delay from 0.5s to 0.3s */
}

/* Bottom border */
.bottom-line {
  bottom: 0;
  right: 0;
  height: 2px; /* Thickness for bottom border */
  width: 100%;
  transform: scaleX(0); /* Initially hidden */
  transform-origin: right; /* Start from the right */
  transition: transform 0.3s ease 0.6s; /* Reduced delay from 1s to 0.6s */
}

/* On hover, animate the borders */
.product-card:hover .top-line {
  transform: scaleX(1); /* Animate top border from left to right */
}

.product-card:hover .left-line {
  transform: scaleY(1); /* Animate left border from bottom to top */
}

.product-card:hover .right-line {
  transform: scaleY(1); /* Animate right border from top to bottom */
}

.product-card:hover .bottom-line {
  transform: scaleX(1); /* Animate bottom border from right to left */
}

/* Reverse the animations when not hovered */
.product-card:not(:hover) .top-line {
  transform: scaleX(0); /* Hide top border */
}

.product-card:not(:hover) .left-line {
  transform: scaleY(0); /* Hide left border */
}

.product-card:not(:hover) .right-line {
  transform: scaleY(0); /* Hide right border */
}

.product-card:not(:hover) .bottom-line {
  transform: scaleX(0); /* Hide bottom border */
}

/* Sale Badge */
.sale-badge {
  position: absolute;
  top: 5%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem; /* Equivalent to text-xs */
  padding: 0.25rem 0.5rem; /* Equivalent to p-1 */
  text-align: center;
  width: 30%;
  z-index: 30;

  /* Initial state: hidden */
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* On hover, show the sale badge */
.product-card:hover .sale-badge {
  opacity: 1;
  transform: translateY(0);
}

/* Image container inside the product card */
.product-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
  will-change: transform; /* Optimization for the animation */
}

/* Scale the image on hover */
.product-card:hover .product-image {
  transform: scale(1.1); /* Increase image size to 110% */
}

/* Ensure the product card has relative positioning for correct hover effects */
.product-card {
  position: relative;
  overflow: hidden; /* Ensure the image stays within the card boundaries */
}
