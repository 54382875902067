
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scroll-container {
  display: flex;
  width: 200%; /* Since content is duplicated */
  animation: scroll 30s linear infinite;
}

.scroll-container.paused {
  animation-play-state: paused;
}

.scroll-item {
  flex: 0 0 auto;
  width: auto;
}

.scroll-item img {
  height: 100px; /* Make all images the same size */
  width: 100px; /* Ensure uniformity */
  object-fit: contain;
}

.fade-left,
.fade-right {
  position: absolute;
  top: 0;
  width: 6rem; /* Adjust as needed */
  height: 100%;
  pointer-events: none;
}

.fade-left {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), transparent);
}

.fade-right {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), transparent);
}